<template>
  <ion-page>
    <ion-content :fullscreen="true" style="background: #F2F7FF;--background: #F2F7FF;position:relative;">
      <div class="head">
        <img style="width:100%;height:100%;" src="../../public/assets/images/head.png">
        <div style="position:absolute;left: 50%;top: 30%;transform: translateY(-30%);transform: translateX(-50%);text-align: center;width:30vw;z-index:210;">
          <LogoLottie :play="playLogoLottie" />
        </div>
      </div>
      <div @click="router.back()" style="position: absolute;font-size: 16px;left: 24px;color: white;z-index: 501;top:6.2vh;">
        <img src="../../public/assets/images/shevron.png" style="width: 8px; height: 13px;margin-right:4px;" />
        {{ t('username.back') }}
      </div>
      <div style="padding: 0 16px;">
        <Pagination :page="4" :pages="7" style="margin-top:27vh;" />
        <div style="margin-top:5vh;font-size: 22px;color: #3c4456;">{{ t('gender.iam') }}</div>
        <div class="margin: 18px 20px 0px 20px;height: 50px;position: relative;">
          <ion-button :class="activeButtonWoman ? 'btn-gender-active' : 'btn-gender-not-active'" expand="block" @click="checkGender(1)">{{ t('gender.woman') }}
            <img v-show="activeButtonWoman" src="../../public/assets/images/done.png" style="width:19px;height:14px;position:absolute;top:18px;right:10px;" />
          </ion-button>
        </div>
        <div class="margin: 18px 20px 0px 20px;height: 50px;position: relative;">
          <ion-button :class="activeButtonMan ? 'btn-gender-active' : 'btn-gender-not-active'" expand="block" @click="checkGender(2)">{{ t('gender.man') }}
            <img v-show="activeButtonMan" src="../../public/assets/images/done.png" style="width: 19px;height: 14px;position: absolute;top: 18px;right: 10px;" />
          </ion-button>
        </div>
        <div class="margin: 18px 20px 0px 20px;height: 50px;position: relative;">
          <ion-button :class="activeButtonMore ? 'btn-gender-active' : 'btn-gender-not-active'" expand="block" @click="checkGender(3)">{{ t('gender.more') }}
            <img v-show="activeButtonMore" src="../../public/assets/images/done.png" style="width: 19px;height: 14px;position: absolute;top: 18px;right: 10px;" />
          </ion-button>
        </div>
        <ion-button
          :disabled="!(activeButtonWoman || activeButtonMan || activeButtonMore)"
          :class="activeButtonWoman || activeButtonMan || activeButtonMore ? 'btnLogin-active' : 'btnLogin-not-active'"
          @click="setGender"
          expand="block">
          {{ t('gender.continue') }}
        </ion-button>
      </div>
      <ion-modal :is-open="isModal">
        <ion-content class="ion-padding" style="background: #F2F7FF;--background: #F2F7FF;">
          <ion-row>
            <ion-col size="3"></ion-col>
            <ion-col size="6" style="text-align:center;font-style: normal;font-weight: 400;font-size: 18px;line-height: 25px;color: #3C4456;">
              <div @click="isModal=false">{{ t('gender.modalHeader') }}</div>
            </ion-col>
            <ion-col size="3" style="text-align:right;font-style: normal;font-weight: 400;font-size: 16px;line-height: 22px;color: #5798F6;padding-top:6px;">
              <div @click="genderMoreDone">{{ t('gender.modalDone') }}</div>
            </ion-col>
          </ion-row>
          <p style="font-family: 'Noto Sans', sans-serif; font-size: 16px; color: #3c4456">{{ t('gender.modalText1') }}</p>
          <ion-select
            interface="action-sheet"
            @ionChange="selectGender"
            :placeholder="t('gender.modalSelect')"
            :okText="t('gender.modalOk')"
            style="--placeholder-color: #cee0f2;background: white;height: 40px;border-radius: 6px;color: #3c4456;font-size: 16px;"
          >
            <ion-select-option
              v-for="(option, index) in optionsSelect"
              :key="index"
              :value="option.code"
              >{{ option.name }}</ion-select-option
            >
          </ion-select>
          <div style="width: 100%; border-bottom: 1px solid #cee0f2; margin-top: 24px;opacity:0.6;"></div>
          <p style="font-family: 'Noto Sans', sans-serif; font-size: 16px; color: #3c4456">
            {{ t('gender.modalText2') }}
          </p>
          <div style="width: 100%; height: 108px; background: white">
            <div @click="isMen=true;isWomen=false" style="width: 100%;height: 54px;margin-left: 16px;border-bottom: 1px solid rgba(206, 224, 242, 0.5);position: relative;padding-top: 1px;cursor: pointer;">
              <p style="font-size: 16px; color: #3c4456">{{ t('gender.men') }}</p>
              <img v-show="isMen" src="../../public/assets/images/done.png" style="width: 17px; height: 13px; position: absolute; top: 20px; right: 40px"  />
            </div>
            <div @click="isWomen=true;isMen=false" style="width: 100%;height: 54px;margin-left: 16px;position: relative;padding-top: 1px;cursor: pointer;">
              <p style="font-size: 16px; color: #3c4456">{{ t('gender.women') }}</p>
              <img v-show="isWomen" src="../../public/assets/images/done.png" style="width: 17px; height: 13px; position: absolute; top: 20px; right: 40px" />
            </div>
          </div>
        </ion-content>
      </ion-modal>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonButton, IonModal, IonRow, IonCol, IonSelect, IonSelectOption } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import LogoLottie from '../components/LogoD.vue'
import Pagination from '../components/Pagination.vue'
import { getAuth } from "firebase/auth";
const auth = getAuth()
import axios from 'axios'

export default defineComponent({
  name: 'RegGenderPage',
  setup() {
    const { t } = useI18n()
    const playLogoLottie = ref(false)
    const router = useRouter()
    const profile = JSON.parse(localStorage.getItem("userProfile"));
    const activeButtonWoman = ref(false)
    const activeButtonMan = ref(false)
    const activeButtonMore = ref(false)
    const isMen = ref(true)
    const isWomen = ref(false)
    const isModal = ref(false)
    const optionsSelect = ref([])
    const gender = ref(null)
    const getToken = async () => {
      const token = await auth.currentUser.getIdToken();
      axios.get('https://us-central1-dutiap-project.cloudfunctions.net/api/v2/genders', {
        headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        console.log('resp', resp)
        optionsSelect.value = resp.data
      })
    }
    getToken()
    const checkGender = (num) => {
      activeButtonWoman.value = num === 1 ? true : false
      activeButtonMan.value = num === 2 ? true : false
      activeButtonMore.value = num === 3 ? true : false
      isModal.value = num === 3 ? true : false
      if (num === 1) {
        profile.gender = 15
        profile.showInSearchFor = 2
      }
      if (num === 2) {
        profile.gender = 23
        profile.showInSearchFor = 1
      }
      if (isModal.value) {
        gender.value = null
      }
    }
    const setGender = () => {
      localStorage.setItem('userProfile', JSON.stringify(profile))
      router.push('/showme')
    }
    const selectGender = (e) => {
      profile.gender = e.detail.value
      gender.value = e.detail.value
    }
    const genderMoreDone = () => {
      isModal.value = false
      if (gender.value !== null && isMen.value) {
        profile.showInSearchFor = 1
      }
      if (gender.value !== null && isWomen.value) {
        profile.showInSearchFor = 2
      }
      if (gender.value === null) {
        activeButtonMore.value = false
      }
      console.log('profile',profile)
    }
    setTimeout(() => {
      playLogoLottie.value = true
    }, 1000);
    return {
      t, playLogoLottie, router, activeButtonWoman, activeButtonMan, activeButtonMore, isModal, optionsSelect, isMen, isWomen, gender,
      checkGender, setGender, selectGender, genderMoreDone
    }
  },
  components: {
    IonContent,
    IonPage,
    IonButton,
    IonModal,
    IonRow,
    IonCol,
    IonSelect,
    IonSelectOption,
    LogoLottie,
    Pagination
  }
});
</script>

<style scoped>

ion-modal {
  --border-radius: 16px;
  margin-top: 50px;
}

ion-modal::part(backdrop) {
  background: transparent;
  opacity: 0;
}

.head {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 25vh;
  z-index: 200;
}

.btnLogin-not-active {
  height: 50px;
  --background: white;
  --color: #9ba6bc;
  --border-radius: 10px;
  --box-shadow: 0px 5px 8px rgba(155, 166, 188, 0.35);
  margin-top: 5vh;
  font-size: 18px;
}

.btnLogin-active {
  height: 50px;
  --background: #5798f6;
  --color: white;
  --border-radius: 10px;
  --box-shadow: 0px 5px 8px rgba(155, 166, 188, 0.35);
  margin-top: 5vh;
  font-size: 18px;
}

.btn-gender-not-active {
  height: 50px;
  width: 90%;
  margin: 3vh auto 0px;
  --background: white;
  --background-activated: white;
  --color: #5798f6;
  --border-radius: 10px;
  --box-shadow: 0px 5px 5px rgba(155, 166, 188, 0.1);
  font-size: 18px;
}

.btn-gender-active {
  height: 50px;
  width: 90%;
  margin: 3vh auto 0px;
  --background: white;
  --background-activated: white;
  --color: #5798f6;
  --border-radius: 10px;
  --box-shadow: 0px 5px 5px rgba(155, 166, 188, 0.1);
  --border: 1px solid #5798f6;
  border: 1px solid #5798f6;
  border-radius: 10px;
  font-size: 18px;
}

#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>
