<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div id="container">
        <!-- <div style="position:absolute;width:100%;height:100%;top:0;z-index:1000;"></div> -->
        <div style="color:black;width:80%;text-align:center;margin:auto;">
          <img style="width:200px;" src="../../public/assets/images/Logo_App.png" alt="Dutiap">
          <p style="margin-top:40px;margin-bottom:40px;">{{ t('plug.text1') }}</p>
          <img style="width:150px;" src="../../public/assets/images/qr_pwa.png" alt="Dutiap">
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'HomePage',
  components: {
    IonContent,
    IonPage
  },
  setup() {
    const { t } = useI18n()
    return {
      t
    }
  }
});
</script>

<style scoped>
#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>
