<template>
  <div :style="sstyle">
    <img src="../../public/assets/animation/splash_.gif" style="width:100%;height:100%;" alt="">
    <!-- <Vue3Lottie
      ref="anim"
      :animationData="animation"
      :loop="false"
      :autoPlay="false"
      renderer="svg"
      :rendererSettings="rendererSettings"
      @onComplete="enterFrame"
    /> -->
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";
// import animation from '../../public/assets/animation/splash.json';
export default {
  name: 'SplashPage',
  emits: ["okComplete","okFrame"],
  props: {
    play: Boolean
  },
  setup(props, { emit }) {
    // const animData = require('../../public/assets/animation/splash.json')
    const lHeight = 1300
    const lWidth = 600
    const qf = lHeight / lWidth
    const sstyle = ref('')
    let _height = 0
    let _width = 0
    const hheight = document.documentElement.clientHeight
    const wwidth = document.documentElement.clientWidth
    _height = hheight
    _width = Math.round(_height / qf)
    if (_width < wwidth) {
      _width = wwidth
      _height = Math.round(_width * qf)
    }
    const _top = - Math.abs((hheight - _height) / 2)
    const _left = - Math.abs((wwidth - _width) / 2)
    sstyle.value = 'width: ' + _width + 'px;height: ' + _height + 'px;top: ' + _top + 'px;left: ' + _left + 'px;position: absolute;z-index: 3999;'
    const anim = ref(null)

    setTimeout(() => {
      emit("okComplete")
    }, 4750);
    setTimeout(() => {
      emit("okFrame")
    }, 3343);

    const enterFrame = () => {
      emit("okFrame")
      emit("okComplete")
    }
    watchEffect(() => {
      if (props.play) {
        anim.value.play()
      }
    })

    return {
      anim, 
      enterFrame,
      sstyle
    }
  }
}
</script>

<style scoped>
</style>