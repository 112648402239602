<template>
  <div class="container">
    <lottie-animation
      ref="anim"
      :animationData="require('../../public/assets/animation/waiting_indicator.json')"
      :loop="true"
      :autoPlay="true"
    />
  </div>
</template>

<script>
export default {
  name: 'AnimationWaitPage'
}
</script>

<style scoped>
.container {
  width: 80px;
  height: 80px;
}
</style>