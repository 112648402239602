<template>
  <ion-page>
    <ion-content :fullscreen="true" style="background: #F2F7FF;--background: #F2F7FF;position:relative;">
      <div class="head">
        <img style="width:100%;height:100%;" src="../../public/assets/images/head.png">
        <div style="position:absolute;left: 50%;top: 30%;transform: translateY(-30%);transform: translateX(-50%);text-align: center;width:30vw;z-index:210;">
          <LogoLottie :play="playLogoLottie" />
        </div>
      </div>
      <div @click="router.back()" style="position: absolute;font-size: 16px;left: 24px;color: white;z-index: 501;top:6.2vh;">
        <img src="../../public/assets/images/shevron.png" style="width: 8px; height: 13px;margin-right:4px;" />
        {{ t('showme.back') }}
      </div>
      <div style="padding: 0 16px;">
        <Pagination :page="5" :pages="7" style="margin-top:27vh;" />
        <div style="margin-top:5vh;font-size: 22px;color: #3c4456;">{{ t('showme.header') }}</div>
        <div class="margin: 18px 20px 0px 20px;height: 50px;position: relative;">
          <ion-button :class="activeButtonWoman ? 'btn-gender-active' : 'btn-gender-not-active'" expand="block" @click="checkGender(1)">{{ t('gender.woman') }}
            <img v-show="activeButtonWoman" src="../../public/assets/images/done.png" style="width:19px;height:14px;position:absolute;top:18px;right:10px;" />
          </ion-button>
        </div>
        <div class="margin: 18px 20px 0px 20px;height: 50px;position: relative;">
          <ion-button :class="activeButtonMan ? 'btn-gender-active' : 'btn-gender-not-active'" expand="block" @click="checkGender(2)">{{ t('gender.man') }}
            <img v-show="activeButtonMan" src="../../public/assets/images/done.png" style="width: 19px;height: 14px;position: absolute;top: 18px;right: 10px;" />
          </ion-button>
        </div>
        <div class="margin: 18px 20px 0px 20px;height: 50px;position: relative;">
          <ion-button :class="activeButtonMore ? 'btn-gender-active' : 'btn-gender-not-active'" expand="block" @click="checkGender(3)">{{ t('showme.everyone') }}
            <img v-show="activeButtonMore" src="../../public/assets/images/done.png" style="width: 19px;height: 14px;position: absolute;top: 18px;right: 10px;" />
          </ion-button>
        </div>
        <ion-button
          :disabled="!(activeButtonWoman || activeButtonMan || activeButtonMore)"
          :class="activeButtonWoman || activeButtonMan || activeButtonMore ? 'btnLogin-active' : 'btnLogin-not-active'"
          @click="setShowMe"
          expand="block">
          {{ t('showme.continue') }}
        </ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonButton } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import LogoLottie from '../components/LogoD.vue'
import Pagination from '../components/Pagination.vue'

export default defineComponent({
  name: 'RegGenderPage',
  setup() {
    const { t } = useI18n()
    const playLogoLottie = ref(false)
    const router = useRouter()
    const profile = JSON.parse(localStorage.getItem("userProfile"));
    const activeButtonWoman = ref(false)
    const activeButtonMan = ref(false)
    const activeButtonMore = ref(false)
    const checkGender = (num) => {
      activeButtonWoman.value = false
      activeButtonMan.value = false
      activeButtonMore.value = false
      if (num === 1) {
        activeButtonWoman.value = true
        profile.showMe = 2
      }
      if (num === 2) {
        activeButtonMan.value = true
        profile.showMe = 1
      }
      if (num === 3) {
        activeButtonMore.value = true
        profile.showMe = 3
      }
    }
    const setShowMe = () => {
      localStorage.setItem('userProfile', JSON.stringify(profile))
      console.log('profile', localStorage.getItem('userProfile'))
      router.push('/addphoto')
    }
    setTimeout(() => {
      playLogoLottie.value = true
    }, 1000);
    return {
      t, playLogoLottie, router, activeButtonWoman, activeButtonMan, activeButtonMore,
      checkGender, setShowMe
    }
  },
  components: {
    IonContent,
    IonPage,
    IonButton,
    LogoLottie,
    Pagination
  }
});
</script>

<style scoped>

ion-modal {
  --border-radius: 16px;
  margin-top: 50px;
}

ion-modal::part(backdrop) {
  background: transparent;
  opacity: 0;
}

.head {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 25vh;
  z-index: 200;
}

.btnLogin-not-active {
  height: 50px;
  --background: white;
  --color: #9ba6bc;
  --border-radius: 10px;
  --box-shadow: 0px 5px 8px rgba(155, 166, 188, 0.35);
  margin-top: 5vh;
  font-size: 18px;
}

.btnLogin-active {
  height: 50px;
  --background: #5798f6;
  --color: white;
  --border-radius: 10px;
  --box-shadow: 0px 5px 8px rgba(155, 166, 188, 0.35);
  margin-top: 5vh;
  font-size: 18px;
}

.btn-gender-not-active {
  height: 50px;
  width: 90%;
  margin: 3vh auto 0px;
  --background: white;
  --background-activated: white;
  --color: #5798f6;
  --border-radius: 10px;
  --box-shadow: 0px 5px 5px rgba(155, 166, 188, 0.1);
  font-size: 18px;
}

.btn-gender-active {
  height: 50px;
  width: 90%;
  margin: 3vh auto 0px;
  --background: white;
  --background-activated: white;
  --color: #5798f6;
  --border-radius: 10px;
  --box-shadow: 0px 5px 5px rgba(155, 166, 188, 0.1);
  --border: 1px solid #5798f6;
  border: 1px solid #5798f6;
  border-radius: 10px;
  font-size: 18px;
}

#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>
