<template>
  <ion-page>
    <ion-content :fullscreen="true" style="background: #F2F7FF;--background: #F2F7FF;position:relative;">
      <div class="head">
        <img style="width:100%;height:100%;" src="../../public/assets/images/head.png">
        <div style="position:absolute;left: 50%;top: 30%;transform: translateY(-30%);transform: translateX(-50%);text-align: center;width:30vw;z-index:210;">
          <LogoLottie :play="playLogoLottie" />
        </div>
      </div>
      <div style="padding: 0 16px;">
        <div style="margin-top:35vh;font-size: 22px;color: #3c4456;">{{ t('pin.mycode') }}</div>
        <ion-row style="margin: 0;padding: 4px 0 0 0;">
          <ion-col style="margin: 0;padding: 0;text-align: left;color: #9BA6BC;font-size: 14px;">{{ email }}</ion-col>
          <ion-col v-if="countdown > 0" style="margin: 0;padding: 0;color: #5798F6;font-style: normal;font-weight: 500;font-size: 14px;line-height: 22px;text-align: right;">{{ t('pin.resendcountdown1') }} {{ countdown }} {{ t('pin.resendcountdown2') }}</ion-col>
          <ion-col v-if="countdown === 0" style="margin: 0;padding: 0;color: #5798F6;font-style: normal;font-weight: 500;font-size: 14px;line-height: 22px;text-align: right;" @click="sendPin">{{ t('pin.resend') }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <div style="height:14vh;width:100%;padding:12px;margin-top:1.5vh;">
              <input
                id="pin1"
                type="text"
                v-model="pin1"
                autocomplete="off"
                inputmode="numeric"
                maxlength="1"
                @input="checkPin(1)"
                @click="clearPin(1)"
                class="inp"
                style="border-radius: 6px;height: 100%;width:100%;background: white;color: #3c4456;font-size:6vh;"
              />
            </div>
          </ion-col>
          <ion-col>
            <div style="height:14vh;width:100%;padding:12px;margin-top:1.5vh;">
              <input
                id="pin2"
                type="text"
                v-model="pin2"
                autocomplete="off"
                inputmode="numeric"
                maxlength="1"
                @input="checkPin(2)"
                @click="clearPin(2)"
                class="inp"
                style="border-radius: 6px;height: 100%;width:100%;background: white;color: #3c4456;font-size:6vh;"
              />
            </div>
          </ion-col>
          <ion-col>
            <div style="height:14vh;width:100%;padding:12px;margin-top:1.5vh;">
              <input
                id="pin3"
                type="text"
                v-model="pin3"
                autocomplete="off"
                inputmode="numeric"
                maxlength="1"
                @input="checkPin(3)"
                @click="clearPin(3)"
                class="inp"
                style="border-radius: 6px;height: 100%;width:100%;background: white;color: #3c4456;font-size:6vh;"
              />
            </div>
          </ion-col>
          <ion-col>
            <div style="height:14vh;width:100%;padding:12px;margin-top:1.5vh;">
              <input
                id="pin4"
                type="text"
                v-model="pin4"
                autocomplete="off"
                inputmode="numeric"
                maxlength="1"
                @input="checkPin(4)"
                @click="clearPin(4)"
                class="inp"
                style="border-radius: 6px;height: 100%;width:100%;background: white;color: #3c4456;font-size:6vh;"
              />
            </div>
          </ion-col>
        </ion-row>
        <div class="pin-incorrect-class">
                <div v-if="isIncorrectPinDiv">
                  {{ t('pin.invalidcode') }}
                </div>
              </div>
        <ion-button
          :disabled="!pinActive"
          :class="pinActive ? 'btnLogin-active' : 'btnLogin-not-active'"
          @click="checkPinReq"
          expand="block">
          {{ t('pin.signin') }}
        </ion-button>
        <div style="width: 200px;margin: 12vh auto;text-align: center;color: #5798f6;font-weight: medium;"><a href="https://dutiap.com/support" target="_blank" style="color: #5798f6;text-decoration: none;">{{ t('pin.trouble') }}</a></div>
      </div>
      <!-- <div id="container">
        <strong>Login</strong>
      </div> -->
      <div v-if="isWait" style="position: absolute;width: 100%;height: 100%;top:0;left:0;background: rgba(0, 0, 0, 0.3);z-index: 1200;">
        <div style="width: 80px;margin: auto;margin-top:48vh;">
          <WaitLottie />
        </div>
      </div>
      <div v-if="isAlert" style="width: 100%;height: 100%;position: absolute;top: 0px;background: rgba(215, 222, 230, 0.3);backdrop-filter: blur(10px);z-index: 999;color: #3C4456;">
        <div style="width: 90%;text-align: center;margin: 35vh auto;background-color: white;border-radius: 10px;padding: 20px 16px 40px 16px;color: #3C4456;">
          <p style="font-size: 18px;font-weight: 600;color: #3C4456;">{{ headerAlert }}</p>
          <p style="font-size: 18px;font-weight: 400;color: #3C4456;">{{ textAlert }}</p>
          <ion-button style="height: 50px;margin-top: 40px;box-shadow: 0px 5px 8px rgba(155, 166, 188, 0.35);border-radius: 10px;--background: #5798F6;--color: white;"
            expand="block" @click="goLogin">{{ okAlert }}</ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonButton, IonRow, IonCol } from '@ionic/vue';
import { defineComponent, ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n'
import { getAuth, signInWithCustomToken } from "firebase/auth";
import LogoLottie from '../components/LogoD.vue'
import WaitLottie from '../components/WaitLottie.vue'
import axios from 'axios'
import { useRouter } from 'vue-router'
const auth = getAuth()

export default defineComponent({
  name: 'PinPage',
  setup() {
    const { t } = useI18n()
    const router = useRouter()
    const email = ref('')
    const playLogoLottie = ref(false)
    email.value = localStorage.getItem('email')
    const headerAlert = ref('')
    const textAlert = ref('')
    const okAlert = ref('')
    const isWait = ref(false)
    const isAlert = ref(false)
    const countdown = ref()
    const pin1 = ref('')
    const pin2 = ref('')
    const pin3 = ref('')
    const pin4 = ref('')
    const pin = ref(['','','',''])
    const pinActive = ref(false)
    const isIncorrectPinDiv = ref(false)
    const sendPin = async () => {
      isWait.value = true
      const sendData = {
        email
      }
      const reqPin = await axios.post('https://us-central1-dutiap-project.cloudfunctions.net/api/v2/pin/set', sendData)
      if (reqPin.data.pinSend) {
        // router.push('/pin')
        console.log('pin send', reqPin.data)
        goCountdown()
      }
      isWait.value = false
    }
    const goLogin = () => {
      isAlert.value = false
      router.replace('/')
    }
    const checkPinReq = async () => {
      isWait.value = true
      const sendData = {
        email: email.value,
        pin: parseInt(pin.value.join(''))
      }
      const reqPin = await axios.post('https://us-central1-dutiap-project.cloudfunctions.net/api/v2/pin/check', sendData)
      console.log('reqPin', reqPin)
      if (!reqPin.data.pinCheck) {
        isWait.value = false
        if (reqPin.data.pinTry === false) {
          isAlert.value = true
          headerAlert.value = t('pin.alert1')
          textAlert.value = t('pin.alert2')
          okAlert.value = 'OK'
          correctPin(true)
          pin1.value = ''
          pin2.value = ''
          pin3.value = ''
          pin4.value = ''
          pin.value = ['','','','']
        } else {
          correctPin(false)
        }
      } else {
        const token = reqPin.data.customToken
        console.log('token', token)
        localStorage.setItem('newUser', reqPin.data.newUser)
        /** Авторизуемся с помощью верного пин кода */
        signInWithCustomToken(auth, token)
          .then((res) => {
            isWait.value = false
            console.log('res', res)
            const user = res.user
            axios.get('https://us-central1-dutiap-project.cloudfunctions.net/api/v2/user', {
              headers: {
                'Authorization': 'Bearer ' + user.accessToken,
                'Content-Type': 'application/json'
              }
            })
            .then((resp) => {
              console.log('resp', resp)
              router.push('/main')
            })
            .catch((e) => {
              console.log('e', e.response.status)
              if (e.response.status === 404) {
                router.push('/name')
              }
            })
          })
          .catch((error) => {
            const errorMessage = error.message;
            console.log('errorMessage', errorMessage)
            isWait.value = false
          });
      }
    }
    const correctPin = (num) => {
      const pinEl1 = document.getElementById('pin1')
      const pinEl2 = document.getElementById('pin2')
      const pinEl3 = document.getElementById('pin3')
      const pinEl4 = document.getElementById('pin4')
      if (num) {
        pinEl1.classList.remove('incorrect-pin')
        pinEl2.classList.remove('incorrect-pin')
        pinEl3.classList.remove('incorrect-pin')
        pinEl4.classList.remove('incorrect-pin')
        pinEl1.classList.add('inp')
        pinEl2.classList.add('inp')
        pinEl3.classList.add('inp')
        pinEl4.classList.add('inp')
        isIncorrectPinDiv.value = false
      } else {
        pinEl1.classList.remove('inp')
        pinEl2.classList.remove('inp')
        pinEl3.classList.remove('inp')
        pinEl4.classList.remove('inp')
        pinEl1.classList.add('incorrect-pin')
        pinEl2.classList.add('incorrect-pin')
        pinEl3.classList.add('incorrect-pin')
        pinEl4.classList.add('incorrect-pin')
        isIncorrectPinDiv.value = true
      }
    }
    const checkPin = (num) => {
      switch (num) {
        case 1:
          pin.value[0] = pin1.value.toString()
          document.getElementById('pin1').blur()
          if (pin2.value === '') {
            document.getElementById('pin2').focus()
          }
          break;
        case 2:
          pin.value[1] = pin2.value.toString()
          document.getElementById('pin2').blur()
          if (pin3.value === '') {
            document.getElementById('pin3').focus()
          }
          break;
        case 3:
          pin.value[2] = pin3.value.toString()
          document.getElementById('pin3').blur()
          if (pin4.value === '') {
            document.getElementById('pin4').focus()
          }
          break;
        case 4:
          pin.value[3] = pin4.value.toString()
          document.getElementById('pin4').blur()
          break;
      }
      checkNumPin()
    }
    const clearPin = (num) => {
      correctPin(true)
      switch (num) {
        case 1:
          pin1.value = ''
          break;
        case 2:
          pin2.value = ''
          break;
        case 3:
          pin3.value = ''
          break;
        case 4:
          pin4.value = ''
          break;
      }
      pin.value[num-1] = ''
      checkNumPin()
    }
    const checkNumPin = () => {
      let result = true
      pin.value.forEach(p => {
        if (p === '') result = false
      })
      pinActive.value = result
    }
    const goCountdown = () => {
      countdown.value = 60
      const countInterval = setInterval(() => {
        countdown.value -= 1
        if (countdown.value === 0) { clearInterval(countInterval) }
      }, 1000);
    }
    onMounted(() => {
      document.getElementById('pin1').focus()
      setTimeout(() => {
        playLogoLottie.value = true
      }, 1000);
      goCountdown()
    })
    return {
      t, email, playLogoLottie, isWait, pin1, pin2, pin3, pin4, pin, pinActive, countdown, isIncorrectPinDiv, headerAlert, textAlert, okAlert, isAlert,
      checkPin, sendPin, clearPin, checkPinReq, goLogin
    }
  },
  components: {
    IonContent,
    IonPage,
    IonButton,
    LogoLottie,
    WaitLottie,
    IonRow,
    IonCol
  }
});
</script>

<style scoped>

.head {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 25vh;
  z-index: 200;
}

input { 
  text-align: center;
  padding: 0;
  -webkit-padding-end: 0;
  -padding-inline-end: 0;
  caret-color: transparent;
}

.inp {
  border: transparent;
}

.inp:focus {
  border:2px solid #5798F6;
  border-radius: 6px;
  outline: none !important;
  /* box-shadow: 0 0 10px #5798F6; */
  /* outline:1px solid #5798F6; */
}

.btnLogin-not-active {
  height: 50px;
  --background: white;
  --color: #9ba6bc;
  --border-radius: 10px;
  --box-shadow: 0px 5px 8px rgba(155, 166, 188, 0.35);
  margin-top: 5vh;
}

.btnLogin-active {
  height: 50px;
  --background: #5798f6;
  --color: white;
  --border-radius: 10px;
  --box-shadow: 0px 5px 8px rgba(155, 166, 188, 0.35);
  margin-top: 5vh;
}

#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

.btnPin-active {
  margin-top: 20px;
  height: 50px;
  --background: #5798f6;
  --color: white;
  --border-radius: 10px;
  --box-shadow: 0px 5px 8px rgba(155, 166, 188, 0.35);
}
.pin-incorrect-class {
  text-align: left;
  color: #d80000;
  font-size: 12px;
  font-weight: normal;
  padding: 5px 0 5px 0;
  height: 22px;
}
.incorrect-pin {
  border: 1px solid #FF6969;
}
.correct-pin {
  border: none;
}

</style>
