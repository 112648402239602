<template>
  <ion-app>
    <Splash v-show="isSplash" :play="playSplash" @okComplete="isSplash=false;" @okFrame="isRouter=true" />
    <ion-router-outlet v-show="isRouter" />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet, toastController } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
// import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation';
import Splash from './components/SplashMain.vue'
import { getAuth, onAuthStateChanged } from "firebase/auth";
const auth = getAuth()
import { useRouter } from 'vue-router'
import axios from 'axios'

export default defineComponent({
  name: 'App',
  setup() {
    const router = useRouter()
    const isSplash = ref(true)
    const isRouter = ref(false)
    const playSplash = ref(false)
    router.currentRoute.value.name === 'Login' ? isSplash.value = true : isSplash.value = false
    const isInWebAppChrome = (window.matchMedia('(display-mode: standalone)').matches);
    onAuthStateChanged(auth, async (user) => {
      console.log('router', router.currentRoute.value.name)
      if (router.currentRoute.value.name === 'Login') {
        playSplash.value = true
      }
      if (user) {
        const postConfig = {
          headers: {
            'Authorization': 'Bearer ' + user.accessToken,
            'Content-Type': 'application/json'
          }
        }
        await axios.post('https://us-central1-dutiap-project.cloudfunctions.net/api/v2/profile/geo', {
          latitude: 0,
          longitude: 0,
          ip: ''
        }, postConfig)
        const userRegistered = JSON.parse(user.reloadUserInfo.customAttributes).registered
        if (userRegistered) {
          router.replace('/main')
        } else {
          router.replace('/name')
        }
      } else {
        router.replace('/')
      }
    })
    const presentToast = async (type) => {
      let msg = ''
      let butt = []
      if (type === 'registered') {
        msg = 'Service worker has been registered.'
        butt = [
          {
            text: 'Close',
            role: 'cancel',
            handler: () => { toast.dismiss() }
          }
        ]
      }
      if (type === 'updated') {
        msg = 'New content is available; please refresh.'
        butt = [
          {
            text: 'Refresh',
            role: 'info',
            handler: () => {
              router.replace('/')
              setTimeout(() => {
                window.location.reload()
              }, 500);
            }
          },
          {
            text: 'Close',
            role: 'cancel',
            handler: () => { toast.dismiss() }
          }
        ]
      }
      const toast = await toastController.create({
        message: msg,
        duration: 10000,
        keyboardClose: true,
        cssClass: 'custom-toast',
        buttons: butt
      });
      await toast.present();
      // const { role } = await toast.onDidDismiss();
      // this.roleMessage = `Dismissed with role: ${role}`;
    }
    document.addEventListener('swUpdated', () => {
      if (isInWebAppChrome) {
        setTimeout(() => {
          presentToast('updated')
        }, 5000);
      }
    }, {once: true})
    return {
      isSplash, playSplash, isRouter
    }
  },
  components: {
    IonApp,
    IonRouterOutlet,
    Splash
  }
});
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
ion-app {
  max-width: 600px;
  margin: auto;
  background: #F2F7FF;
  font-family: 'Noto Sans', sans-serif;
  --ion-safe-area-top: 30px;
}
ion-toast.custom-toast {
  --background: #FFFFFF;
  --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
  --color: #3C4456;
}

ion-toast.custom-toast::part(button) {
    border-left: 1px solid #d2d2d2;
    color: #030207;
    font-size: 15px;
  }
/* (A) WRONG ORIENTATION - SHOW MESSAGE HIDE CONTENT */
@media only screen and (orientation:landscape) {
  #turn { display:block; }
  #container { display:none; }
}
 
/* (B) CORRECT ORIENTATION - SHOW CONTENT HIDE MESSAGE */
@media only screen and (orientation:portrait) {
  #turn { display:none; }
  #container { display:block; }
}
</style>