import { initializeApp } from "firebase/app"
// import { getFirestore, doc, getDoc } from "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyB2OVDDudYNEMRrwXADE1vc47ATK8UJSMs",
  authDomain: "dutiap-project.firebaseapp.com",
  projectId: "dutiap-project",
  storageBucket: "dutiap-project.appspot.com",
  messagingSenderId: "754153687016",
  appId: "1:754153687016:web:9f1fe405d173a3a9dc865d",
  measurementId: "G-8TJTS4K15Q"
};

initializeApp(firebaseConfig)
