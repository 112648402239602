import './firebase'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { createI18n } from 'vue-i18n'
import LottieAnimation from 'lottie-web-vue'
// import Vue3Lottie from 'vue3-lottie'
// import 'vue3-lottie/dist/style.css'

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import messages from './locale/locale'
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import './registerServiceWorker'
const i18n = createI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en',
  legacy: false,
  messages
})

const app = createApp(App)
  .use(IonicVue, {
    swipeBackEnabled: false
  })
  .use(i18n)
  .use(LottieAnimation)
  // .use(Vue3Lottie)
  .use(router);
  
router.isReady().then(() => {
  app.mount('#app');
  defineCustomElements(window);
});