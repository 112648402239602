import { createRouter, createWebHistory } from '@ionic/vue-router';
// import { RouteRecordRaw } from 'vue-router';
import HomePage from '../views/HomePage.vue'
import HeadPage from '../views/HeadPage.vue'
// import LoginPage from '../views/LoginPage.vue'
import PinPage from '../views/PinPage.vue'
import RegNamePage from '../views/RegNamePage.vue'
import RegBirthdayPage from '../views/RegBirthdayPage.vue'
import RegGenderPage from '../views/RegGenderPage.vue'
import RegShowMePage from '../views/RegShowMePage.vue'
// import RegAddPhotoPage from '../views/RegAddPhotoPage.vue'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/LoginPage.vue'),
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/head',
    name: 'Head',
    component: HeadPage
  },
  {
    path: '/pin',
    name: 'Pin',
    component: PinPage
  },
  {
    path: '/name',
    name: 'Name',
    component: RegNamePage
  },
  {
    path: '/birthday',
    name: 'Birthday',
    component: RegBirthdayPage
  },
  {
    path: '/gender',
    name: 'Gender',
    component: RegGenderPage
  },
  {
    path: '/showme',
    name: 'Showme',
    component: RegShowMePage
  },
  {
    path: '/addphoto',
    name: 'Addphoto',
    component: () => import('../views/RegAddPhotoPage.vue'),
  },
  {
    path: '/description',
    name: 'Description',
    component: () => import('../views/DescriptionPage.vue'),
  },
  {
    path: '/main',
    name: 'Main',
    component: () => import('../views/MainPage.vue'),
  },
  {
    path: '/share',
    name: 'Share',
    component: () => import('../components/Settings/SharePage.vue'),
  },
  {
    path: '/shareprofile',
    name: 'ShareProfile',
    component: () => import('../components/Settings/ShareProfilePage.vue'),
  },
  {
    path: '/editphotos',
    name: 'EditPhotoPage',
    component: () => import('../components/Settings/EditPhotoPage.vue')
  },
  {
    path: '/mainsettings',
    name: 'MainSettingsPage',
    component: () => import('../components/Settings/MainSettingsPage.vue')
  },
  {
    path: '/changeusername',
    name: 'ChangeUserNamePage',
    component: () => import('../components/Settings/ChangeUserName.vue')
  },
  {
    path: '/changeshowme',
    name: 'ChangeShowMePage',
    component: () => import('../components/Settings/ChangeShowMe.vue')
  },
  {
    path: '/changeabout',
    name: 'ChangeAboutPage',
    component: () => import('../components/Settings/ChangeAbout.vue')
  },
  {
    path: '/changenotifications',
    name: 'ChangeNotificationsPage',
    component: () => import('../components/Settings/ChangeNotifications.vue')
  },
  {
    path: '/maincard',
    name: 'MainCardPage',
    component: () => import('../components/Main/MainCard.vue')
  },
  {
    path: '/maincardsearch',
    name: 'MainCardSearchPage',
    component: () => import('../components/Main/MainCardSearch.vue')
  },
  {
    path: '/chat',
    name: 'ChatPage',
    component: () => import('../components/Chat/Chat.vue')
  },
  {
    path: '/stand',
    name: 'StandPage',
    component: () => import('../views/Stand.vue')
  }
]

function DetectDevice() {
  let isMobile = window.matchMedia || window.msMatchMedia;
  if(isMobile) {
      let match_mobile = isMobile("(pointer:coarse)");
      return match_mobile.matches;
  }
  return false;
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next)=>{
  const uAgent = window.navigator.userAgent.toLowerCase()
  if (DetectDevice()) {
    if (uAgent.indexOf('iphone') != -1) {
      const isInWebAppiOS = (window.navigator.standalone === true);
      if (isInWebAppiOS) {
        next()
      } else {
        if (to.path != '/stand') { next('/stand') } else next()
      }
    } else {
      const isInWebAppChrome = (window.matchMedia('(display-mode: standalone)').matches);
      if (isInWebAppChrome) {
        // if (localStorage.getItem('newUpdate') !== 'true') {
        //   next()
        // }
        next()
      } else {
        if (to.path != '/stand') { next('/stand') } else next()
      }
    }
    // if (to.name !== 'Login' && to.name !== 'Pin' && to.name !== 'Name' && to.name !== 'Birthday' && to.name !== 'Gender' && to.name !== 'Showme' && to.name !== 'Addphoto' && to.name !== 'Description' && to.name !== 'Main' && to.name !== 'Share' && to.name !== 'EditPhotoPage' && to.name !== 'MainSettingsPage' && to.name !== 'ChangeUserNamePage' && to.name !== 'ChangeShowMePage' && to.name !== 'ChangeAboutPage' && to.name !== 'ChangeNotificationsPage' && to.name !== 'MainCardPage' && to.name !== 'MainCardSearchPage' && to.name !== 'ChatPage') {
    //   next({
    //     name: 'Login',
    //     replace: true
    //   })
    // } else {
    //   next()
    // }
  } else {
    if (to.name !== 'Head') {
      next({
        name: 'Head',
        replace: true
      })
    } else {
      next()
    }
  }
})

export default router
