<template>
  <ion-page>
    <ion-content :fullscreen="true" style="background: #F2F7FF;--background: #F2F7FF;position:relative;">
      <div class="head">
        <img style="width:100%;height:100%;" src="../../public/assets/images/head.png">
        <div style="position:absolute;left: 50%;top: 30%;transform: translateY(-30%);transform: translateX(-50%);text-align: center;width:30vw;z-index:210;">
          <LogoLottie :play="playLogoLottie" />
        </div>
      </div>
      <div @click="router.back()" style="position: absolute;font-size: 16px;left: 24px;color: white;z-index: 501;top:6.2vh;">
        <img src="../../public/assets/images/shevron.png" style="width: 8px; height: 13px;margin-right:4px;" />
        {{ t('birthday.back') }}
      </div>
      <div style="padding: 0 16px;">
        <Pagination :page="3" :pages="7" style="margin-top:27vh;" />
        <div style="margin-top:5vh;font-size: 22px;color: #3c4456;">{{ t('birthday.mybirthday') }}</div>
        <ion-row>
          <ion-col>
            <div style="height:6.8vh;width:100%;padding:1vw;margin-top:1.5vh;">
              <input
                id="bd1"
                type="text"
                v-model="bd1"
                autocomplete="off"
                inputmode="numeric"
                :placeholder="t('birthday.d')"
                maxlength="1"
                @input="checkBd(1)"
                @click="clearBd(1)"
                class="inp"
                style="border-radius: 6px;height: 100%;width:100%;background: white;color: #3c4456;font-size:3.2vh;"
              />
            </div>
          </ion-col>
          <ion-col style="margin-right: 5.3vw">
            <div style="height:6.8vh;width:100%;padding:1vw;margin-top:1.5vh;">
              <input
                id="bd2"
                type="text"
                v-model="bd2"
                autocomplete="off"
                inputmode="numeric"
                :placeholder="t('birthday.d')"
                maxlength="1"
                @input="checkBd(2)"
                @click="clearBd(2)"
                class="inp"
                style="border-radius: 6px;height: 100%;width:100%;background: white;color: #3c4456;font-size:3.2vh;"
              />
            </div>
          </ion-col>
          <ion-col>
            <div style="height:6.8vh;width:100%;padding:1vw;margin-top:1.5vh;">
              <input
                id="bd3"
                type="text"
                v-model="bd3"
                autocomplete="off"
                inputmode="numeric"
                :placeholder="t('birthday.m')"
                maxlength="1"
                @input="checkBd(3)"
                @click="clearBd(3)"
                class="inp"
                style="border-radius: 6px;height: 100%;width:100%;background: white;color: #3c4456;font-size:3.2vh;"
              />
            </div>
          </ion-col>
          <ion-col style="margin-right: 5.3vw">
            <div style="height:6.8vh;width:100%;padding:1vw;margin-top:1.5vh;">
              <input
                id="bd4"
                type="text"
                v-model="bd4"
                autocomplete="off"
                inputmode="numeric"
                :placeholder="t('birthday.m')"
                maxlength="1"
                @input="checkBd(4)"
                @click="clearBd(4)"
                class="inp"
                style="border-radius: 6px;height: 100%;width:100%;background: white;color: #3c4456;font-size:3.2vh;"
              />
            </div>
          </ion-col>
          <ion-col>
            <div style="height:6.8vh;width:100%;padding:1vw;margin-top:1.5vh;">
              <input
                id="bd5"
                type="text"
                v-model="bd5"
                autocomplete="off"
                inputmode="numeric"
                :placeholder="t('birthday.y')"
                maxlength="1"
                @input="checkBd(5)"
                @click="clearBd(5)"
                class="inp"
                style="border-radius: 6px;height: 100%;width:100%;background: white;color: #3c4456;font-size:3.2vh;"
              />
            </div>
          </ion-col>
          <ion-col>
            <div style="height:6.8vh;width:100%;padding:1vw;margin-top:1.5vh;">
              <input
                id="bd6"
                type="text"
                v-model="bd6"
                autocomplete="off"
                inputmode="numeric"
                :placeholder="t('birthday.y')"
                maxlength="1"
                @input="checkBd(6)"
                @click="clearBd(6)"
                class="inp"
                style="border-radius: 6px;height: 100%;width:100%;background: white;color: #3c4456;font-size:3.2vh;"
              />
            </div>
          </ion-col>
          <ion-col>
            <div style="height:6.8vh;width:100%;padding:1vw;margin-top:1.5vh;">
              <input
                id="bd7"
                type="text"
                v-model="bd7"
                autocomplete="off"
                inputmode="numeric"
                :placeholder="t('birthday.y')"
                maxlength="1"
                @input="checkBd(7)"
                @click="clearBd(7)"
                class="inp"
                style="border-radius: 6px;height: 100%;width:100%;background: white;color: #3c4456;font-size:3.2vh;"
              />
            </div>
          </ion-col>
          <ion-col>
            <div style="height:6.8vh;width:100%;padding:1vw;margin-top:1.5vh;">
              <input
                id="bd8"
                type="text"
                v-model="bd8"
                autocomplete="off"
                inputmode="numeric"
                :placeholder="t('birthday.y')"
                maxlength="1"
                @input="checkBd(8)"
                @click="clearBd(8)"
                class="inp"
                style="border-radius: 6px;height: 100%;width:100%;background: white;color: #3c4456;font-size:3.2vh;"
              />
            </div>
          </ion-col>
        </ion-row>
        <div style="width: 100%">
          <p style="margin-top: 8px;font-family: 'Noto Sans', sans-serif;font-size: 12px;line-height: 16px;color: #9BA6BC;">{{ t('birthday.describtion') }}</p>
        </div>
        <ion-button
          :disabled="!isActive"
          :class="isActive ? 'btnLogin-active' : 'btnLogin-not-active'"
          @click="isAlert = true"
          expand="block">
          {{ t('birthday.continue') }}
        </ion-button>
      </div>
      <div v-if="isAlert" style="width: 100%;height: 100%;position: absolute;top: 0px;background: rgba(215, 222, 230, 0.3);backdrop-filter: blur(10px);z-index: 999;color: #3C4456;">
        <div style="width: 90%;text-align: center;margin: 35vh auto;background-color: white;border-radius: 10px;padding: 20px 16px 40px 16px;color: #3C4456;">
          <p style="font-size: 18px;font-weight: 600;color: #3C4456;">{{ ageText }}</p>
          <p style="font-size: 16px;font-weight: 400;color: #3C4456;line-height: 22px;">{{ t('birthday.text1') }}</p>
          <ion-button style="height: 50px;margin-top: 40px;box-shadow: 0px 5px 8px rgba(155, 166, 188, 0.35);border-radius: 10px;--background: #5798F6;--color: white;"
            expand="block" @click="setBd">{{ t('birthday.save') }}</ion-button>
          <ion-button style="height: 50px;margin-top: 16px;--box-shadow: 0px 5px 8px rgba(155, 166, 188, 0.35);--border-radius: 10px;--background: white;--color: #9ba6bc;"
            expand="block" @click="isAlert=false">{{ t('birthday.edit') }}</ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonButton, IonRow, IonCol } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import LogoLottie from '../components/LogoD.vue'
import Pagination from '../components/Pagination.vue'

function getAge(arr) {
  var day = arr[0] === '0' ? parseInt(arr[1]) : parseInt(arr[0] + arr[1])
  var month = arr[2] === '0' ? parseInt(arr[3]) : parseInt(arr[2] + arr[3])
  var year = parseInt(arr[4] + arr[5] + arr[6] + arr[7]);
  var today = new Date();
  var birthDate = new Date(year, month - 1, day); // 'month - 1' т.к. нумерация месяцев начинается с 0
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

function isValidDate(dateString)
{
    // First check for the pattern
    if(!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString))
        return false;

    // Parse the date parts to integers
    var parts = dateString.split("/");
    var day = parseInt(parts[1], 10);
    var month = parseInt(parts[0], 10);
    var year = parseInt(parts[2], 10);

    // Check the ranges of month and year
    if(year < 1000 || year > 3000 || month == 0 || month > 12)
        return false;

    var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

    // Adjust for leap years
    if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
        monthLength[1] = 29;

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
}

export default defineComponent({
  name: 'RegBirthdayPage',
  setup() {
    const { t } = useI18n()
    const playLogoLottie = ref(false)
    const router = useRouter()
    const profile = JSON.parse(localStorage.getItem("userProfile"));
    const bd1 = ref('')
    const bd2 = ref('')
    const bd3 = ref('')
    const bd4 = ref('')
    const bd5 = ref('')
    const bd6 = ref('')
    const bd7 = ref('')
    const bd8 = ref('')
    let pinBd = ['','','','','','','',''];
    const ageText = ref('')
    const age = ref()
    const isActive = ref(false)
    const isAlert = ref(false)
    const checkBd = (num) => {
      switch (num) {
        case 1:
          pinBd[0] = bd1.value.toString()
          document.getElementById('bd1').blur()
          if (bd2.value === '') {
            document.getElementById('bd2').focus()
          }
          break;
        case 2:
          pinBd[1] = bd2.value.toString()
          document.getElementById('bd2').blur()
          if (bd3.value === '') {
            document.getElementById('bd3').focus()
          }
          break;
        case 3:
          pinBd[2] = bd3.value.toString()
          document.getElementById('bd3').blur()
          if (bd4.value === '') {
            document.getElementById('bd4').focus()
          }
          break;
        case 4:
          pinBd[3] = bd4.value.toString()
          document.getElementById('bd4').blur()
          if (bd5.value === '') {
            document.getElementById('bd5').focus()
          }
          break;
        case 5:
          pinBd[4] = bd5.value.toString()
          document.getElementById('bd5').blur()
          if (bd6.value === '') {
            document.getElementById('bd6').focus()
          }
          break;
        case 6:
          pinBd[5] = bd6.value.toString()
          document.getElementById('bd6').blur()
          if (bd7.value === '') {
            document.getElementById('bd7').focus()
          }
          break;
        case 7:
          pinBd[6] = bd7.value.toString()
          document.getElementById('bd7').blur()
          if (bd8.value === '') {
            document.getElementById('bd8').focus()
          }
          break;
        case 8:
          pinBd[7] = bd8.value.toString()
          document.getElementById('bd8').blur()
          break;
      }
      checkBdStr()
    }
    const clearBd = (num) => {
      switch (num) {
        case 1:
          bd1.value = ''
          break;
        case 2:
          bd2.value = ''
          break;
        case 3:
          bd3.value = ''
          break;
        case 4:
          bd4.value = ''
          break;
        case 5:
          bd5.value = ''
          break;
        case 6:
          bd6.value = ''
          break;
        case 7:
          bd7.value = ''
          break;
        case 8:
          bd8.value = ''
          break;
      }
      pinBd[num-1] = ''
      checkBdStr()
    }
    const checkBdStr = () => {
      let result = true
      pinBd.forEach(pin => {
        if (pin === '') {
          result = false
        }
      })
      if (!isValidDate(pinBd[0] + pinBd[1] + '/' + pinBd[2] + pinBd[3] + '/' + pinBd[4] + pinBd[5] + pinBd[6] + pinBd[7])) {
        result = false
      }
      isActive.value = result
      if (result) {
        age.value = getAge(pinBd)
        ageText.value = t('birthday.text2') + age.value + t('birthday.text3');
      }
    }
    const setBd = async () => {
      profile.birthday = pinBd[4] + pinBd[5] + pinBd[6] + pinBd[7] + '-' + pinBd[2] + pinBd[3] + '-' + pinBd[0] + pinBd[1]
      localStorage.setItem('userProfile', JSON.stringify(profile))
      console.log('profile', localStorage.getItem('userProfile'))
      isAlert.value = false
      router.push('/gender')
    }
    setTimeout(() => {
      playLogoLottie.value = true
    }, 1000);
    return {
      t, playLogoLottie, router, bd1, bd2, bd3, bd4, bd5, bd6, bd7, bd8, isActive, ageText, isAlert,
      setBd, checkBd, clearBd
    }
  },
  components: {
    IonContent,
    IonPage,
    IonButton,
    IonRow,
    IonCol,
    LogoLottie,
    Pagination
  }
});
</script>

<style scoped>

ion-col {
  padding-inline: 0px;
  --padding-inline: 0px;
}

@supports (padding-inline: 0px) {
  ion-col {
    padding-inline: 0px;
    --padding-inline: 0px;
  }
}

input { 
  text-align: center;
  padding: 0;
  -webkit-padding-end: 0;
  -padding-inline-end: 0;
  caret-color: transparent;
}

input::-webkit-input-placeholder {
  color: #CEE0F2;
}

input::placeholder {
  color: #CEE0F2;
}

.inp {
  border: transparent;
}

.inp:focus {
  border:2px solid #5798F6;
  border-radius: 6px;
  outline: none !important;
  /* box-shadow: 0 0 10px #5798F6; */
  /* outline:1px solid #5798F6; */
}

.head {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 25vh;
  z-index: 200;
}

.btnLogin-not-active {
  height: 50px;
  --background: white;
  --color: #9ba6bc;
  --border-radius: 10px;
  --box-shadow: 0px 5px 8px rgba(155, 166, 188, 0.35);
  margin-top: 5vh;
}

.btnLogin-active {
  height: 50px;
  --background: #5798f6;
  --color: white;
  --border-radius: 10px;
  --box-shadow: 0px 5px 8px rgba(155, 166, 188, 0.35);
  margin-top: 5vh;
}

#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>
