<template>
  <div style="text-align: center;left: 0;right: 0;">
    <ion-grid :style="gridStyle">
      <ion-row class="ion-justify-content-center" style="height: 100%">
        <ion-col v-for="(dot, index) in arrPages" :key="index" style="width: 26px">
          <div class="containerDot">
            <div :class="dot.external">
              <div :class="dot.internal"></div>
            </div>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script>
import { IonRow, IonCol, IonGrid } from '@ionic/vue';
import { ref, toRefs } from 'vue';

export default {
  name: 'PaginationPage',
  props: {
    page: Number,
    pages: Number,
  },
  setup(props) {
    const gridStyle = ref("width: 130px; margin: auto")
    const { page, pages } = toRefs(props)
    if (pages.value === undefined) { pages.value = 5 }
    if (page.value === undefined) { page.value = 1 }
    gridStyle.value = "width: " +(pages.value * 26 + pages.value * 4) + "px; margin: auto"
    const arrPages = ref([{}])
    for (let i = 0; i < pages.value; i++) {
      if (i === page.value - 1) {
        arrPages.value[i] = {
          external: "externalActiveDot",
          internal: "internalActiveDot",
        }
      }
      if (i < page.value - 1) {
        arrPages.value[i] = {
          external: "externalDot",
          internal: "internalDot",
        }
      }
      if (i > page.value - 1) {
        arrPages.value[i] = {
          external: "externalDot",
          internal: "internalDisableDot",
        }
      }
    }
    return {
      arrPages, gridStyle
    }
  },
  components: { IonRow, IonCol, IonGrid }
}
</script>

<style scoped>
.containerDot{
  width: 18px;
  height: 18px;
  margin: 4px;
}

.externalActiveDot{
  width: 18px;
  height: 18px;
  transform: matrix(1, 0, 0, -1, 0, 0);
  border-radius: 50%;
  border: 1px solid #5798F6;
  padding: 3px;
}

.internalActiveDot{
  width: 100%;
  height: 100%;
  background: #5798F6;
  transform: matrix(1, 0, 0, -1, 0, 0);
  border-radius: 50%;
}

.externalDot{
  width: 18px;
  height: 18px;
  transform: matrix(1, 0, 0, -1, 0, 0);
  border-radius: 50%;
  padding: 4px;
}

.internalDot{
  width: 100%;
  height: 100%;
  background: #5798F6;
  transform: matrix(1, 0, 0, -1, 0, 0);
  border-radius: 50%;
}

.internalDisableDot{
  width: 100%;
  height: 100%;
  background: #5798F6;
  opacity: 0.3;
  transform: matrix(1, 0, 0, -1, 0, 0);
  border-radius: 50%;
}


#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>