const messages = {
  en: {
    login: {
      phone: 'Whats your phone number?',
      enter: 'Enter phone number',
      signin: 'Create Account / Sign In',
      agree1: 'By tapping Create Account or Sign In, you are agre to our',
      agree2: 'Terms',
      agree3: '. Learn how we process you data in our',
      agree4: 'Privacy Policy',
      agree5: 'and',
      agree6: 'Cookies Policy',
      trouble: 'Trouble Signing In?',
      alert1: 'Oooops!',
      alert2: 'Too many attempts. Try in one minutes.'
    },
    pin: {
      mycode: 'My code is',
      resendcountdown1: 'Resend in',
      resendcountdown2: 'sec',
      resend: 'Resend',
      invalidcode: 'The code you entered is invalid, please try again.',
      signin: 'Sign In',
      agree1: 'By tapping Create Account or Sign In, you are agre to our',
      agree2: 'Terms',
      agree3: '. Learn how we process you data in our',
      agree4: 'Privacy Policy',
      agree5: 'and',
      agree6: 'Cookies Policy',
      trouble: 'Trouble Signing In?',
      alert1: 'Access is denied!',
      alert2: 'Ended up attempts to enter the PIN code',
      alert3: 'Oooops!',
      alert4: 'Something went wrong. Try again',
      loading1: 'Please wait...',
    },
    email: {
      email: 'Whats your e-mail?',
      placeholder: 'Enter email',
      describtion: 'This is for event notifications.',
      continue: 'Continue',
      alert1: 'Invalid email adress!',
      alert2: 'Try again'
    },
    username: {
      back: 'Back',
      myname: 'My name is',
      placeholder: 'Enter name',
      describtion: 'This is how it will appear in Dutiap.',
      continue: 'Continue'
    },
    plug: {
      text1: "You're really close to being able to sample the other side.",
      text2: "To access the experience simply scan this QR code with your iPhone."
    },
    stand: {
      text1: "You're just two taps away.",
      text2: "Tap the ",
      text3: " icon at the bottom of your screen, scroll down the pop-up menu till you see ",
      text4: "'Add to home screen' ",
      text5: "and hit add."
    },
    birthday: {
      back: 'Back',
      mybirthday: 'My Birthday is',
      d: 'D',
      m: 'M',
      y: 'Y',
      describtion: 'Your age will be public.',
      continue: 'Continue',
      text1: 'Make sure you enter the correct age: you cannot сhange your date of birth later.',
      save: 'Save',
      edit: 'Edit',
      text2: 'Are you ',
      text3: ' years old?'
    },
    gender: {
      back: 'Back',
      iam: 'I am a',
      woman: 'Woman',
      women: 'Women',
      man: 'Man',
      men: 'Men',
      more: 'More',
      continue: 'Continue',
      modalDone: 'Done',
      modalHeader: 'Gender',
      modalSelect: 'Select One',
      modalOk: 'Select',
      modalText1: 'I am',
      modalText2: 'Include me in Searches for'
    },
    location: {
      back: 'Back',
      enable: 'Enable Location',
      describtion: 'Location will allow us to recommend users near you.',
      allow: 'Allow local',
      notallow: 'Not now'
    },
    notifications: {
      back: 'Back',
      keepme: 'Keep me posted',
      text1: 'Find out when you get a match or message.',
      iwant: 'I want to be notified',
      notnow: 'Not now'
    },
    description: {
      back: 'Back',
      skip: 'Skip',
      title1: 'YooHoo! New tree!',
      subtitle1: 'You just signed up for Dutiap, which means we have planted a new tree thanks to you. Help us plant forests - tell your friends about our app!',
      button1: 'Got it',
      title2: 'Welcome to Dutiap!',
      subtitle2: 'Rate photos and profiles of other users. The number of rated profiles is equal to the number of ratings for your profile. ',
      button2: 'Got it',
      title3: 'Choose the best photo!',
      subtitle3: 'In your profile you can view the rating of each photo and choose the best ones.',
      button3: 'Got it',
      title4: 'Recommendations',
      subtitle4: 'Our algotithm remembers your preferences and every 24 hours recommends 10 users just for you.',
      button4: 'Got it',
      title5: 'Matches',
      subtitle5: 'If you mutually like or rate each other above 8, then you match and can start chatting.',
      button5: 'Start',
    },
    showme: {
      header: 'Show me',
      back: 'Back',
      woman: 'Woman',
      man: 'Man',
      everyone: 'Every one',
      continue: 'Continue'
    },
    addphoto: {
      back: 'Back'
    },
    settings: {
      invited: 'Friends invited:',
      allinvited: 'Total friends invited:'
    },
    share: {
      back: 'Back',
      settings: 'Settings',
      text: 'Share you personal code for reason!'
    },
    editphotos: {
      skip: 'Back',
      done: 'Done',
      header: 'Edit profile',
      name: 'Name',
      about: 'About me',
      text1: 'Upload your photo',
      text2: 'Hold, drag and drop to reorder',
      text3: 'Please follow our ',
      text4: 'Terms of Use',
      text5: ' when posting content.',
      add: 'Add photo'
    },
    modalphoto: {
      cancel: 'Cancel',
      take: 'Take',
      choose: 'Choose'
    },
    firstphoto: {
      cancel: 'Cancel',
      change: 'Change main photo',
      add: 'Add a new photo'
    },
    corp: {
      back: 'Back',
      save: 'Save'
    },
    mainsettings: {
      save: 'Save',
      profile: 'Profile',
      settings: 'Settings',
      email: 'Email',
      username: 'Username',
      show: 'Show me',
      age: 'Age range',
      insearch: 'Show me in search',
      text1: 'While turned off, you will no be shown in the app search. People will still be able to rate your photos as much as you have already rated other photos. You can still see and chat with your matches.',
      notifications: 'Notifications',
      help: 'Help & Support',
      about: 'About',
      logout: 'Log out',
      delete: 'Delete account',
      text2: 'Log out',
      text3: 'Are you sure want to log out?',
      cancel: 'Cancel'
    },
    changeabout: {
      settings: 'Settings',
      about: 'About',
      privacy: 'Privacy Policy',
      terms: 'Terms of Service',
      licenses: 'Licenses',
      aboutus: 'About us'
    },
    changenotifications: {
      settings: 'Settings',
      notifications: 'Notifications',
      push: 'Push Notifications',
      push_newmatches: 'New matches',
      push_newmatches_text: 'You just got a new match',
      push_messages: 'Messages',
      push_messages_text: 'Someone send you a new message',
      push_recommendations: 'Recommendations',
      push_recommendations_text: 'You just got a new recommendation',
      email: 'E-mail Notification',
      email_newmatches: 'New matches',
      email_newmatches_text: 'You just got a new match',
      email_messages: 'Messages',
      email_messages_text: 'Someone send you a new message',
      email_recommendations: 'Recommendations',
      email_recommendations_text: 'You just got a new recommendation',
      promotions: 'Promotions',
      promotions_text1: 'I want to receive news, updates and',
      promotions_text2: 'offers from Dutiap'
    },
    changeshowme: {
      settings: 'Settings',
      showme: 'Show me',
      men: 'Men',
      women: 'Women',
      everyone: 'Everyone'
    },
    changeusername: {
      settings: 'Settings',
      username: 'Username',
      confirm: 'Confirm',
      delete: 'Delete'
    }
  }
}

export default messages