<template>
  <div class="container">
    <lottie-animation
      ref="anim"
      :animationData="require('../../public/assets/animation/logo_login.json')"
      :loop="false"
      :autoPlay="false"
    />
  </div>
</template>

<script>
import { watchEffect, ref } from "vue";
// import axios from "axios"
export default {
  name: 'LogoDPage',
  props: {
    play: Boolean
  },
  setup(props) {

    const anim = ref(null)

    watchEffect(() => {
      if (props.play) {
        anim.value.play()
      }
    })

    return {
      anim
    }
  }
}
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
}
</style>