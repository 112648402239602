<template>
  <ion-page>
    <ion-content :fullscreen="true" style="background: #F2F7FF;--background: #F2F7FF;position:relative;">
      <div class="head">
        <img style="width:100%;height:100%;" src="../../public/assets/images/head.png">
        <div style="position:absolute;left: 50%;top: 30%;transform: translateY(-30%);transform: translateX(-50%);text-align: center;width:30vw;z-index:210;">
          <LogoLottie :play="playLogoLottie" />
        </div>
      </div>
      <div @click="router.back()" style="position: absolute;font-size: 16px;left: 24px;color: white;z-index: 501;top:6.2vh;">
        <img src="../../public/assets/images/shevron.png" style="width: 8px; height: 13px;margin-right:4px;" />
        {{ t('username.back') }}
      </div>
      <div style="padding: 0 16px;">
        <Pagination :page="2" :pages="7" style="margin-top:27vh;" />
        <div style="margin-top:5vh;font-size: 22px;color: #3c4456;">{{ t('username.myname') }}</div>
        <ion-input
          id="inputName"
          type="text"
          v-model="name"
          autocomplete="off"
          inputmode="text"
          style="border-radius: 10px;height: 40px;--padding-start: 10px;--background: white;--color: #3c4456;--placeholder-color: #cee0f2;margin-top:3vh;"
          :placeholder="t('username.placeholder')"
        ></ion-input>
        <div style="width: 100%">
          <p style="margin-top: 8px;font-family: 'Noto Sans', sans-serif;font-size: 12px;line-height: 16px;color: #9BA6BC;">This is how it will appear in Dutiap.</p>
        </div>
        <ion-button
          :disabled="name.length < 3"
          :class="name.length > 2 ? 'btnLogin-active' : 'btnLogin-not-active'"
          @click="setName"
          expand="block">
          {{ t('username.continue') }}
        </ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonButton, IonInput } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import LogoLottie from '../components/LogoD.vue'
import Pagination from '../components/Pagination.vue'

export default defineComponent({
  name: 'RegNamePage',
  setup() {
    const { t } = useI18n()
    const name = ref('')
    const playLogoLottie = ref(false)
    const router = useRouter()
    const profile = {};
    const setName = async () => {
      profile.name = name.value
      localStorage.setItem('userProfile', JSON.stringify(profile))
      console.log('profile', localStorage.getItem('userProfile'))
      router.push('/birthday')
    }
    setTimeout(() => {
      playLogoLottie.value = true
    }, 1000);
    return {
      t, name, playLogoLottie, router,
      setName
    }
  },
  components: {
    IonContent,
    IonPage,
    IonButton,
    IonInput,
    LogoLottie,
    Pagination
  }
});
</script>

<style scoped>

.head {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 25vh;
  z-index: 200;
}

.btnLogin-not-active {
  height: 50px;
  --background: white;
  --color: #9ba6bc;
  --border-radius: 10px;
  --box-shadow: 0px 5px 8px rgba(155, 166, 188, 0.35);
  margin-top: 5vh;
}

.btnLogin-active {
  height: 50px;
  --background: #5798f6;
  --color: white;
  --border-radius: 10px;
  --box-shadow: 0px 5px 8px rgba(155, 166, 188, 0.35);
  margin-top: 5vh;
}

#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>
